:root {
    --blue: #1692ad;
    --white: #ffffff;
    --pink: #ff9191;
    --black: #000000;
}

html,
body {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.container {
    text-align: center;
}

.textcenter {
    text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Josefin Sans', sans-serif;
}

p {
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    line-height: 1.6;
    font-size: 18px;
}

h1 {
    font-size: 55px;
    line-height: 1.4;
}

h2 {
    font-size: 50px;
}

.header-back {
    background: var(--pink);
    margin: 30px auto 0 !important;
}

#main__header {
    display: inline-block;
    width: 100%;
}

.container {
    width: 1300px;
    margin: auto;
}

.tb__space {
    padding: 60px 0px;
}

.tp__space {
    padding: 60px 0 0;
}

.bp__space {
    padding: 0 0 60px;
}

.flex__row {
    display: flex;
    flex-direction: row;
}

.flex__align-center {
    align-items: center;
}

.flex__justify-center {
    justify-content: center;
}

.flex__justify-spbtwn {
    justify-content: space-between;
}

.maintitle h1,
.maintitle h2 {
    width: fit-content;
    margin: 0px auto 30px;
    animation: titlemove 3s ease-in-out infinite;
}

.default__btn input {
    background: var(--blue);
    color: var(--white);
    padding: 20px 70px;
    font-size: 22px;
    font-family: 'Josefin Sans';
    border: 0px;
    border-radius: 30px;
    text-transform: uppercase;
    cursor: pointer;
    box-shadow: 0px 0px 11px #000;
}

.default__btn input:hover {
    box-shadow: 4px 3px 10px #000 inset;
}

.logo {
    display: inline-flex;
}

.mainlogo {
    text-align: center;
}

.mainlogo img {
    width: 200px;
}

.header__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo img {
    width: 125px;
}

.logo a {
    display: flex;
}

.menu ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu ul li {
    padding: 0 15px;
    position: relative;
}

.menu li a {
    color: var(--white);
    text-decoration: none;
    font-size: 28px;
    text-transform: uppercase;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    position: relative;
    display: block;
    margin: 0 2px;
    padding: 0.625rem 1.25rem;
    text-transform: uppercase;
    overflow: hidden;
}

.menu li a:before {
    box-sizing: border-box;
    transform: translateX(100%);
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 99.5%;
    height: 2px;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
}

.menu li a:after {
    box-sizing: border-box;
    transform: translateX(-100%);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 99.5%;
    height: 2px;
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
}

.menu li a:hover:before {
    transition: 0.1s transform linear, 0.1s height linear 0.1s;
    transform: translateX(0);
    height: 100%;
    border-color: var(--blue);
}

.menu li a:hover:after {
    transition: 0.1s transform linear 0.2s, 0.1s height linear 0.3s;
    transform: translateX(0);
    height: 100%;
    border-color: var(--blue);
}

.mainhero {
    margin-top: 40px;
}

.hero__left h1 {
    display: flex;
    flex-wrap: wrap;
}

.hero__right {
    width: 48%;
}

.hero__right img {
    width: 500px;
}

.hero__left {
    width: 48%;
    text-align: left;
}

span.bgblue {
    background: var(--blue);
    padding: 10px 10px;
}

span.bgpink {
    background: var(--blue);
    display: inline-block;
    color: var(--white);
}

.typed-cursor {
    color: var(--blue);
}

.about__inner {
    width: 70%;
    margin: 0 auto 60px;
}

.contactform {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: auto;
    flex-wrap: wrap;
}

.contactform .full {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0px 30px;
    flex-wrap: wrap;
}

.contactform .half {
    width: 48%;
    display: inline-flex;
}

.contactform .full textarea {
    width: 100%;
    min-height: 200px;
}

.contactform .full input {
    height: 26px;
    width: 100%;
}

.contactform .full input,
.contactform .full textarea {
    border-radius: 30px;
    box-shadow: 6px 4px 7px var(--blue) inset;
    text-align: center;
    font-family: 'Josefin Sans';
    font-size: 26px;
    border: 2px solid var(--pink);
    padding: 16px 6px;
    color: var(--blue);
    outline: 0;
}

.contactform .full input::placeholder,
.contactform .full textarea::placeholder {
    color: var(--blue);
    opacity: 0.6;
}

.contactform .default__btn {
    text-align: right;
}

.divider {
    width: 40%;
    margin: auto;
}

.divider img {
    width: 100%;
}

.copyright {
    background: var(--blue);
    text-align: center;
    color: #fff;
    padding: 3px 10px;
    text-transform: uppercase;
}

.skillcircle {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.skill__title {
    background: aquamarine;
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.skills__outer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.skills-right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.skilltitle {
    background: rgb(172 173 190 / 46%);
    margin: 2px 0px;
    min-width: 200px;
    color: #e08e1b;
}

.hm__contact {
    position: relative;
}

.handemail {
    position: absolute;
    right: -18px;
    bottom: -8px;
    z-index: -9;
}

@keyframes backscroll {
    0% {
        background-size: 30%;
    }
    40% {
        background-size: 50%;
    }
    80% {
        background-size: 80%;
    }
    100% {
        background-size: 100%;
    }
}

@keyframes titlemove {
    0% {
        transform: translate(0%, 0);
    }
    50% {
        text-shadow: 0 25px 50px rgba(0, 0, 0, 0.75);
    }
    100% {
        transform: translate(0%, 0);
    }
}

@keyframes skilltitlemove {
    0% {
        margin-left: -500px;
        opacity: 0;
    }
    100% {
        margin-left: 0px;
        opacity: 1;
    }
}

@media screen and (max-width:1400px) {
    .container {
        width: 80%;
    }
    h1 {
        font-size: 48px;
    }
    .hero__right img {
        width: 100%;
    }
}

@media screen and (max-width:1200px) {
    h1 {
        font-size: 42px;
    }
}

@media screen and (max-width:1024px) {
    h1 {
        font-size: 36px;
    }
}

@media screen and (max-width:800px) {
    .flex__row {
        flex-direction: column;
    }
    .hero__left {
        width: 100%;
    }
    .container {
        width: 90%;
    }
    p {
        font-size: 16px;
    }
    .hero__left h1 {
        min-height: 110px;
    }
    .menu {
        display: none;
    }
    #mainmenu.show {
        position: fixed;
        top: 0;
        background: var(--pink);
        width: 100%;
        height: 100%;
        left: 0;
        display: block;
        opacity: 0.8;
    }
    .menu.show ul {
        display: flex;
        width: 100%;
        position: fixed;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    #menuToggle {
        display: flex;
        flex-direction: column;
        left: -20px;
        top: 0;
        position: relative;
        z-index: 1;
        -webkit-user-select: none;
        user-select: none;
    }
    #menuToggle input {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        top: -7px;
        left: -5px;
        cursor: pointer;
        opacity: 0;
        z-index: 2;
        -webkit-touch-callout: none;
    }
    #menuToggle span {
        display: block;
        width: 33px;
        height: 4px;
        margin-bottom: 5px;
        position: relative;
        background: var(--blue);
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), opacity 0.55s ease;
    }
    #menuToggle span:first-child {
        transform-origin: 0% 0%;
    }
    #menuToggle span:nth-last-child(2) {
        transform-origin: 0% 100%;
    }
    #menuToggle input:checked~span {
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -1px);
        background: var(--blue);
    }
    #menuToggle input:checked~span:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }
    #menuToggle input:checked~span:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);
    }
    .skillcircle {
        grid-template-columns: repeat(2, 1fr);
    }
    .contactform .half {
        width: 100%;
    }
    .contactform .half:first-child {
        margin-bottom: 30px;
    }
}

@media screen and (min-width:800px) {
    input#menucheck {
        display: none;
    }
}

@media screen and (max-width:640px) {
    .hero__right {
        width: 100%;
    }
    .skills-left img {
        width: 100%;
    }
}

@media screen and (max-width:500px) {
    .skillcircle {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width:425px) {
    h1 {
        font-size: 30px;
    }
}