svg {
    width: 100%;
    height: 230px;
    margin-bottom: 60px;
}

@media screen and (max-width:640px) {
    svg {
        height: 100px;
    }
}